<template>
  <Dialog>
    <Form
      id="product-subscription-edit-form"
      submit-label="formFields.form.confirm"
      cancel-label="formFields.form.cancel"
      @submit="
        onSubmit(data.subscription.id, quantity, interval, desiredDeliveryDay)
      "
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <template #default>
        <div class="product-grid">
          <div
            v-if="data.product?.productSubscription?.discount"
            class="flex product-grid__discount"
          >
            <SubscriptionDiscountInfoBox
              class="w-full mb-md"
              text="shop.subscription.discount.edit.label"
              :discount="data.product.productSubscription.discount"
            />
          </div>
          <div class="flex product-grid__image">
            <div class="relative w-[65px] sm:w-[150px] h-auto shrink-0">
              <ProductImage
                :image-url="data.product?.media?.cover"
                :alt-text="data.product?.media?.alt"
                class="w-[65px] h-[65px] sm:h-[120px] sm:w-[120px] object-scale-down mr-auto"
              />
            </div>
          </div>
          <div class="product-grid__productInfo">
            <div class="font-bold text-md md:text-lg font-deco-alt">
              {{ data.product.name }}
            </div>
            <div class="text-sm md:text-md">
              <span v-if="data.product?.variantName">
                {{ data.product.variantName }}
                <span v-if="data.product?.manufacturer"> | </span>
              </span>
              <span v-if="data.product?.manufacturer">
                {{ data.product.manufacturer.name }}
              </span>
            </div>
            <div class="text-sm md:text-md">
              <span
                >{{ t('shop.cart.productFinder.itemNumber') }}
                {{ data.product?.productNumber }}</span
              >
            </div>
          </div>
          <div class="text-sm product-grid__price md:text-md">
            <SubscripitonPrice
              :product="data.product"
              :dialog="true"
              :quantity="quantity"
            />
          </div>
        </div>
        <ContentSeparator class="relative mt-md separator-overwrite" />
        <div class="productEdit-grid">
          <div class="productEdit-grid__quantity">
            <div class="flex flex-col w-full flex-nowrap gap-2xs">
              <span class="font-bold">{{ t('shop.cart.general.amount') }}</span>
              <QuantityPicker
                :key="quantity"
                v-model="quantity"
                :teleport-to="'#dialogBody'"
                class="max-w-[167px] w-full"
                :lower-limit="data.product?.productSubscription?.lowerLimit"
                :step-size="data.product?.productSubscription?.increaseQuantity"
                :debounce-emit="500"
                @update:model-value="(nv) => (quantity = nv)"
                @click.prevent
              />
            </div>
          </div>
          <div class="productEdit-grid__interval">
            <Dropdown
              id="aboInterval"
              v-model="interval"
              :value="deliveryIntervals[2]"
              :options="deliveryIntervals"
              name="aboInterval"
              :label="t('shop.pdp.productSubscription.aboInterval')"
            ></Dropdown>
          </div>
          <div class="productEdit-grid__delivery">
            <Dropdown
              id="aboDeliveryDay"
              v-model="desiredDeliveryDay"
              :value="deliveryDays[3]"
              :options="deliveryDays"
              name="aboDeliveryDay"
              :label="t('shop.pdp.productSubscription.deliveryDay')"
            >
              <template #info>
                {{ t('shop.pdp.productSubscription.edit.info') }}
              </template>
            </Dropdown>
          </div>
          <div class="productEdit-grid__infoBanner">
            <div
              v-if="!productIsAvailable"
              class="flex h-full text-sm px-sm md:text-md bg-status-danger-lighter rounded-alt-md"
            >
              <div
                class="flex items-center icon-box bg- bg-status-danger-light"
              >
                <FaIcon
                  icon-class="fal fa-truck-fast"
                  classes="text-white mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                {{ t('shop.productSubscription.edit.notAvailableText') }}
              </div>
            </div>
            <div
              v-else-if="!disabled"
              class="flex h-full text-sm px-sm md:text-md bg-[var(--thm-bg-base)] rounded-alt-md"
            >
              <div
                class="flex items-center icon-box bg-[var(--thm-grey-light)]"
              >
                <FaIcon
                  icon-class="fal fa-truck-fast"
                  classes="text-black mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                {{ t('shop.productSubscription.nextDelivery.long') }}
                <span class="font-bold text-status-success-dark">{{
                  nextOrderDate
                }}</span>
                <SubscriptionBookedPrice
                  v-if="data.subscription.bookedOrderPrice"
                  :booked-order-price="data.subscription.bookedOrderPrice"
                  :quantity="data.subscription.quantity"
                />
              </div>
            </div>
            <div
              v-else
              class="flex h-full rounded-alt-md px-sm bg-status-warn-lighter my-sm"
            >
              <div class="flex items-center icon-box bg-status-warn-light">
                <FaIcon
                  icon-class="fal fa-circle-exclamation"
                  classes="text-black mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                <Trans
                  keypath="shop.productSubscription.edit.infoText"
                  tag="span"
                >
                  <span class="font-bold">{{ nextOrderDate }}</span>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <BasicButton
          class="mb-xs md:mb-0 mt-md px-sm md:px-0"
          :label="t('shop.productSubscription.cancel.title')"
          :type="EButtonType.BUTTON"
          :btn-style="EButtonStyle.LIGHT"
          full-width
          @click="openCancelDialog(data.subscription, data.product)"
        ></BasicButton>
      </template>
    </Form>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import type { Product } from '~/server/transformers/shop/product/types';
import { BasicButton, Dropdown, QuantityPicker, ProductImage } from '@/complib';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import FaIcon from '@/components/fa-icon.vue';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import { useProductSubscriptionEdit } from '@/components/dialog/productSubscriptionEdit/useProductSubscriptionEdit';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import {
  useSubscriptionBox,
  useSubscriptionDeliveryNow,
} from '@/components/page/product/components/buyBox/components/options/subscription/useSubscriptionBox';
import Trans from '~/components/Trans.vue';
import SubscriptionBookedPrice from '@/components/page/product/components/buyBox/components/options/subscription/components/subscriptionBookedPrice.vue';
import SubscripitonPrice from '@/components/page/product/components/buyBox/components/options/subscription/components/subscription-price.vue';
import SubscriptionDiscountInfoBox from '@/components/page/product/components/buyBox/components/options/subscription/components/subscriptionDiscountInfoBox.vue';
const { t } = useTrans();
const dialogStore = useDialogStore();
const data = dialogStore.getDialogData<{
  subscription: ProductSubscriptionItem;
  product: Product;
  triggerReload: boolean;
}>();

const { deliveryIntervals, deliveryDays } = useSubscriptionBox({
  product: data.product,
});

const productIsAvailable = !data.subscription?.tempNotAvailable;

const { nextOrderDate, disabled, openCancelDialog } =
  useSubscriptionDeliveryNow(data.subscription);

const { quantity, interval, desiredDeliveryDay, onSubmit, getNewDeliveryDate } =
  useProductSubscriptionEdit(data.subscription);

dialogStore.setHeadline(t('shop.productSubscription.edit.title'));

watch(
  () => [interval.value, desiredDeliveryDay.value],
  async () => {
    const newDate = await getNewDeliveryDate(
      data.subscription.id,
      interval.value,
      desiredDeliveryDay.value,
    );
    nextOrderDate.value = newDate;
  },
);
</script>
<style scoped lang="postcss">
.product-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'discount discount'
    'image productInfo'
    'image price';

  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}

.productEdit-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'quantity quantity'
    'interval interval'
    'delivery delivery'
    'infoBanner infoBanner';
  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}
.product-grid__image {
  grid-area: image;
  @apply mr-sm mt-2xs;
}
.product-grid__productInfo {
  grid-area: productInfo;
  @apply pl-sm;
}

.product-grid__discount {
  grid-area: discount;
}

.productEdit-grid__interval {
  grid-area: interval;
  @apply my-sm;
}
.productEdit-grid__quantity {
  grid-area: quantity;
  @apply my-sm;
}
.productEdit-grid__delivery {
  grid-area: delivery;
}

.productEdit-grid__infoBanner {
  grid-area: infoBanner;
  @apply my-sm;
}

.product-grid__price {
  grid-area: price;
  @apply items-start my-sm pl-sm  justify-center;
}

.separator-overwrite :deep(.separator) {
  @apply !pb-md !px-0;
}

@media (min-width: 960px) {
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'discount discount'
      'image productInfo'
      'image price';

    grid-template-columns: 150px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .productEdit-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'quantity interval'
      'delivery delivery'
      'infoBanner infoBanner';
    grid-template-columns: 200px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .product-grid__image {
    grid-area: image;
    @apply mr-sm mt-2xs;
  }
  .product-grid__productInfo {
    grid-area: productInfo;
  }

  .product-grid__discount {
    grid-area: discount;
  }

  .productEdit-grid__quantity {
    grid-area: quantity;
    @apply mb-sm mt-0;
  }

  .productEdit-grid__interval {
    grid-area: interval;
    @apply mb-sm mt-0 pl-sm;
  }
  .product-gridEdit__delivery {
    grid-area: delivery;
  }

  .product-gridEdit__infoBanner {
    grid-area: infoBanner;
    @apply rounded-alt-md my-sm;
  }
  .product-grid__price {
    grid-area: price;
    @apply items-start my-sm  justify-center;
  }
}
</style>
